.slideshow {
	position: relative;
	top: -40px;
	margin: 0;
	padding: 0;
	list-style: none;
	width: 515px;
	height: 380px;

	&__item {
		position: absolute;
		width: 390px;
		height: 245px;
		overflow: hidden;
  		box-shadow: -4.243px 4.243px 6px 0px rgba(0, 0, 0, 0.15);

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}

		&.active {
			z-index: 99;
			border: 2px solid #f1ece4;
		}
	}
}