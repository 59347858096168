.doc {
	position: relative;
	padding: 20px 60px 20px 70px;
	border-width: 2px;
	border-color: rgb(241, 236, 228);
	border-style: solid;
	background-color: rgb(255, 255, 255);
	margin-bottom: 10px;

	&__ico {
		position: absolute;
		left: 22px;
		top: 22px;
		width: 27px;
		height: 32px;

		svg {
			max-width: 100%;
			max-height: 100%;
			fill: @green;
		}
	}

	&__title {
		font-size: 20px;
		font-weight: 500;
		color: rgb(44, 43, 46);
		line-height: 1.4;
		text-decoration: none;
		border-bottom: 1px solid @green;

		&:hover {
			border: none;
		}
	}

	&__desc {
		line-height: 1.4;

		p {
			margin: 12px 0 7px;
		}
	}

	&__link {
		font-size: 15px;
		font-weight: 500;
		color: @green;
		line-height: 1.4;

		&:hover {
			text-decoration: none;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}