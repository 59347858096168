﻿@media (max-width: 1000px) {
	.modal {
		padding: 20px 50px 50px;
		max-width: 95%;
	}

	.section {
		padding: 20px 0;
	}

	.welc {
		&__sup-logo {
			position: static;
			width: 95%;
			margin: 0 auto;
			padding: 40px;
		}

		&__sup-content {
			width: 95%;
			margin: 0 auto;
			max-width: 100%;
			background-size: cover;

			p {
				left: 50%;
			}
		}

		&__ban {
			margin-bottom: 30px;
		}

		&__sup {
			margin: 0;
		}
	}

	.newslist {
		justify-content: space-between;

		&__item {
			width: 48%;

			&:nth-child(1n) {
				margin-right: 0;
			}
		}

		&__content {
			justify-content: space-between;
		}
	}

	.activity {
		&__item {
			padding-right: 0;
			padding-left: 50px;

			&::before {
				font-size: 32px;
			}
		}
	}

	.consult {
		padding: 30px 100px;
	}

	.copyright {
		&__letsrock {
			position: static;
			margin-top: 10px;
		}
	}

	.header {
		&__l-col,
		&__r-col {
			display: block;
			width: 100%;
		}
	}

	.title_page {
		width: auto;
	}

	.morenews {
		margin: 0;
		padding: 10px;
		background-size: cover;

		.newslist {
			justify-content: space-around;
		}
	}

	.newslist_more .newslist__item {
		margin-bottom: 20px;
	}
	.breadcrumbs {
		padding: 20px;
	}
}

@media (max-width: 768px) {
	.content {
		ul {
			margin: 0;
		}
	}	

	.gallery {
		&__item {
			width: 32%;
			margin: 0 2% 20px 0;
		}
	}

	.caption {
		font-size: 20px;
	}

	.section {
		padding: 20px;

		&__cell:nth-child(1n) {
			display: block;
			width: 100%;
			padding: 0;
		}

		&__block {
			padding: 0;

			&:nth-child(1n) {
				background: none;
			}
		}

		&__block .container {
			display: block;
		}

		&__block_empt {
			display: none;
		}
	}

	.title_page {
		font-size: 26px;
	}

	.doc {
		padding: 20px;

		&__title {
			font-size: 16px;
		}

		&__ico {
			display: none;
		}
	}

	.slideshow {
		display: none;
		margin: 0 auto;
		max-width: 515px;
		width: 100%;

		&__item {
			width: 100%;
		}
	}

	.contacts {
		padding: 0 20px;
	}

	.contacts-personal {
		margin: 0 0 30px;
	}

	#map {
		position: static;
		width: 100%;
		height: 340px;
	}

	.slider {
		.flickity-prev-next-button {
			display: block;
		}
	}

	.newslist {
		&__item {
			padding: 20px 0 20px 30px;
			width: 100%;
		}

		&__item_more {
			display: block;

			span {
				white-space: nowrap;
			}
		}
	}

	.welc {
		&__sup-content {
			width: 100%;
			height: auto;
			padding: 30px 20px;

			p {
				position: static;
				width: 100%;
				transform: none;

			}
		}

		&__ban {
			height: 210px;
			margin-bottom: 36px;

			p {
				font-size: 20px;
			}
		}

		&__ban-text {
			left: 20px;
			top: 40px;
			transform: none;
			padding-right: 20px;
		}

		&__sup-logo {
			width: 100%;
			padding: 30px 20px 50px;

			p {
				font-size: 13px;
				margin-bottom: 20px;
			}

			img {
				max-width: 90%;
			}
		}
	}

	.section_act {
		background: url('../img/bg_3.png');
		padding-bottom: 50px;
	}

	.activity {
		display: block;
		margin: 25px 0;

		&__item:not(:nth-child(1)):not(:nth-child(2)) {
			display: none;
		}

		&__item {
			width: 100%;

			&::before {
				font-size: 28px;
			}
		}
	}

	.banner {
		padding: 80px 20px;
		height: 440px;

		&__content {
			position: relative;
			top: auto;
			left: auto;
			transform: none;
		}
	}

	.consult {
		padding: 30px 20px;
	}

	.form {
		&__cell {
			display: block;

			&:nth-child(1n) {
				width: 100%;
				padding: 0;
			}
		}
	}

	.checkbox {
		p {
			display: inline;
		}
	}

	.footer {
		.logo {
			display: none;
		}

		&__body {
			padding: 20px 20px 40px;
			flex-direction: column;
		}

		&__block {
			order: 2;

			&:last-child {
				order: 1;
			}
		}

		&__bot {
			padding: 50px 20px;
		}

		&__social.social {
			margin: 0;
		}

		&__contacts {
			margin-top: 20px;

			p {
				margin-bottom: 5px;
			}
		}
	}

	.social {

		&__item {
			width: 28px;
			height: 28px;
			margin-left: 6px;
		}
	}

	.copyright {
		&__letsrock {
			margin-top: 50px;
		}
	}

	.nav {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		background: #4aa454;
		padding: 70px 0 100px;
		width: 220px;
		z-index: 99;

		&__item_drop {
			.nav__link {
				pointer-events: none;
			}

			&::before {
				display: block;
			}
		}

		&__link {
			display: block;
			padding: 14px 20px;
			color: white;
			text-align: left;
			border-bottom: 1px solid #4c8f54;

			&::before,
			&::after {
				display: none;
			}

			&.current {
				background: #4c8f54;
			}
		}

		&__drop {
			display: none;	
			position: static;
			transform: none;
			width: 100%;
			opacity: 1;
			visibility: visible;
			margin: 0;
			padding: 0;

			a {
				text-align: left;
				padding: 14px 20px;
			}
		}
	}

	.header {
		&__body {
			padding: 0;
		}

		&__l-col {
  			box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  		}

  		&__r-col {
  			padding: 0 20px;
  		}

  		&__line {
  			padding: 0;
  			margin-top: 20px;
  		}

  		&__phone {
  			margin: 0;
  			font-size: 18px;
  		}
	}

	.logo {
		svg {
			width: 230px;
			padding: 20px 0 10px 20px;
		}
	}

	.menu {
		display: block;
		z-index: 999;
	}

	.search {
		padding: 20px;

		&__tip {
			display: none;
		}

		&__form {
			display: block;
		}

		&__field {
			display: block;
			width: 100%;	

			input {
				width: 100%;
				box-sizing: border-box;
			}
		}

		&__btn {
			margin-top: 20px;
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	.pagination {
		text-align: center;
		
		&__item {
			margin: 0;
		}
	}

	.modal {
		padding: 0 20px 20px;
	}
}

@media (max-width: 600px) {
	.gallery {
		&__item {
			width: 48%;
			margin: 0 4% 20px 0;

			&:nth-child(3n) {
				margin: 0 4% 20px 0;
			}

			&:nth-child(even) {
				margin-right: 0;
			}
		}

		
	}
}

@media (max-width: 400px) {
	.gallery {
		&__item {
			display: block;
			width: 100%;

			&:nth-child(1n) {
				margin: 0 0 20px;
			}
		}
	}
}