﻿@import url("normalize.less");
@import url("fonts.less");
@import url("mixins.less");
@import url("icons.less");

@import url("../components/header/style.less");
@import url("../components/logo/style.less");
@import url("../components/nav/style.less");
@import url("../components/sections/style.less");
@import url("../components/docs/style.less");
@import url("../components/slideshow/style.less");
@import url("../components/contacts/style.less");
@import url("../components/morenews/style.less");
@import url("../components/newsg/style.less");
@import url("../components/gallery/style.less");
@import url("../components/search/style.less");
@import url("../components/pagination/style.less");
@import url("../components/footer/style.less");

/* variables */
@base: 15px;
@font: "Rubik";

@green: #68b571;
@red: #ff5e5e;
	

/* * * * * * * * * * *
* 					 *
* Global             *
* Styles             *
*                    *
* * * * * * * * * * */

html {
	font-size: @base;
}

html,
body {
	width: 100%;
	height: 100%;	
}

body {
	font-family: @font, sans-serif;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-feature-settings: 'liga', 'kern';
	overflow-x: hidden;

	.flex(column);

	* {
		box-sizing: border-box;
	}
}

[data-img] {
	cursor: pointer;
}

.container {
	position: relative;
	width: 100%;
	max-width: 1170px;

	margin: 0 auto;
	padding: 0;
	box-sizing: border-box;
}

.container_mid {
	max-width: 1030px;
}

.container_lar {
	max-width: 1110px;
}

.container_content {
	max-width: 730px;
}

.content {
	position: relative;	
	font-size: 15px;
	font-weight: 300;
	line-height: 1.4;
	margin-bottom: 50px;	

	p {
		margin: 0 0 24px;
	}

	img {
		max-width: 100%;
		margin-bottom: 30px;
	}

	iframe {
		width: 100%;
		max-width: 590px;
		height: 340px;
	}

	.bg {
		position: relative;
		z-index: 1;
		padding: 25px 0 21px;
		text-align: center;
		margin-bottom: 30px;

		&::before {
			position: absolute;
			z-index: -1;
			left: 50%;
			top: 0; 
			transform: translateX(-50%);
			width: 100vw;
			height: 100%;
			content: '';
			background: url('../img/shtrih_bg.jpg') center;
		}

		img {
			margin: 0;
		}
	}

	ul {
		margin: 0 0 0 26px;
		padding: 0;
		list-style: none;

		li {
			position: relative;
			padding-left: 34px;
			margin-bottom: 25px;

			&::before {
				position: absolute;
				left: 0;
				top: 8px;
				width: 17px;
				height: 2px;
				content: '';
				background: @green;
			}
		}
	}
}

.content_news {
	&::before {
		position: absolute;
		z-index: -1;
		left: 50%;
		top: 20px;
		transform: translateX(-50%);
		width: 100vw;
		height: 380px;
		content: '';
		background: url('../img/shtrih_bg.jpg') center;
	}
}

.wrapper {
	flex: 1 0 auto;
	// background: #f1f1f1;
}

.header,
.footer {
	// background: #222;
	// color: #fff;
}

.svg-collections {
	display: none;
}

.social {
	margin: 0;
	padding: 0;
	list-style: none;

	&__item {
		position: relative;
		display: inline-block;
		width: 32px;
		height: 32px;
		background-color: lightgray;
		margin-left: 8px;

		&:first-child {
			margin-left: 0;	
		}

		a {
			position: absolute;
			left: 0; top: 0; right: 0; bottom: 0;
		}

		svg {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			max-width: 75%;
			max-height: 70%;
			fill: white;
		}
	}

	&__item_vk {
		background-color: #3d6192;
	}

	&__item_fb {
		background-color: #3c5a99;
	}

	&__item_ok {
		background-color: #f0701a;
	}
}

.title {
	font-size: 32px;
	font-weight: 300;
	color: rgb(51, 51, 51);
	line-height: 0.938;
}

.title_wh {
	color: white;
}

.title_page {
	font-size: 42px;
	line-height: 1.2;
	margin-top: 0;
	width: 840px;
}

.caption {
 	font-size: 24px;
 	font-weight: 500;
 	line-height: 1.25;
 	max-width: 515px;
 	display: block;
}

.datenews {
	position: relative;
	padding-left: 19px;
	font-size: 14px;
	font-weight: 500;
	color: rgb(180, 180, 180);
	line-height: 1.786;

	&::before {
		position: absolute;
		left: 1px;
		top: 9px;
		width: 6px;
		height: 6px;
		content: '';
		background: currentColor;
	}
}

.link {
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	color: @green;
	text-decoration: none;
	padding-bottom: 0;
	border-bottom: 2px solid @green;
}

.link_allnews {
	position: relative;
	padding-left: 19px;

	svg {
		position: absolute;
		left: 0;
		top: 1px;
		width: 12px;
		height: 14px;
		fill: @green;
	}
}

.slider {
	margin: 40px 0;

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.flickity-prev-next-button {
		display: none;
		top: auto;
		bottom: -50px;
		transform: none;
		background: none;

		svg {
			display: none;
		}

		&::before {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			border: 9px solid transparent;
			content: '';
		}

		&::after {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			content: '';
			width: 12px;
			height: 8px;
			background: black;
		}

		&.previous {
			left: 50%;
			transform: translateX(-50%);
			margin-left: -20px;

			&::before {
				border-right-color: black;
				left: 0;
			}
		}

		&.next {
			right: auto;
			left: 50%;
			transform: translateX(-50%);
			margin-left: 20px;

			&::before {
				border-left-color: black;
				right: 0;
			}
		}
	}
}

.notify {
	position: relative;
	z-index: 1;
	width: 280px;
	height: 280px;
	margin-right: 30px;
	overflow: hidden;

	&::before {
		position: absolute;
		z-index: 0;
		left: 0;
		top: 0;
		width: 101%;
		height: 100%;
		content: '';
		background: rgba(0,0,0,.5);
		opacity: 1;
		transition: all .3s;
	}

	a {
		position: absolute;	
		left: 0; top: 0; right: 0; bottom: 0;
		color: white;
  		font-weight: 500;
		line-height: 1;
		text-align: left;
		text-decoration: none;
	}

	img {
		position: absolute;
		z-index: -1;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}

	&__date {
		position: absolute;
		left: 20px;
		top: 20px;
		font-size: 18px;
		transition: all .3s;
		opacity: 1;
	}

	&__title {
		position: absolute;
		left: 20px;
		bottom: 15px;
		font-size: 20px;
		margin: 0;
		line-height: 1.4;
		transition: all .3s;
		opacity: 1;
	}

	&:hover {
		&::before {
			opacity: 0;
		}

		.notify {
			&__date,
			&__title {
				opacity: 0;
			}
		}
	}

	&__next {
		position: absolute;
		z-index: 99;
		cursor: pointer;
		top: 50%;
		right: -2px;
		transform: translateY(-50%);
		width: 40px;
		height: 75px;
		background: none;
		border: none;

		&::before {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			content: '';
			border: 35px solid transparent;
			border-right-color: white;
		}

		&::after {
			position: absolute;
			top: 50%;
			right: -5px;
			transform: translateY(-50%);
			content: '';
			border: 10px solid transparent;
			border-left-color: black;
			transition: all .15s;
		}

		&:hover {

			&::after {		
				border-left-color: @green;
			}
		}
	}
}

.btn {
	display: inline-block;
	vertical-align: top;
	height: 40px;	
	color: white;
	background: @green;
	text-decoration: none;
	line-height: 37px;
	font-size: 15px;
	font-weight: 500;
	padding: 0 27px;
	border: 2px solid transparent;
	transition: all .3s;

	&:focus {
		outline: none;
	}

	&:hover,
	&.active {
		background: transparent;
		color: @green;
		border-color: @green;
	}

	&.active {
		pointer-events: none;
	}
}

.btn_tr {
	background: transparent;
	color: white;
	border: 2px solid white;
}

.btn_wh {
	background: white;
	color: @green;
	border-color: @green;
}

.btn_enter {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	height: 46px;
	line-height: 44px;
	font-size: 18px;
	padding: 0 30px;
	margin-bottom: 50px;
}

.form {
	margin-top: 40px;

	&__body {
		display: table;
		width: 100%;
	}

	&__cell {
		display: table-cell;
		vertical-align: top;

		&:first-child {
			width: 45%;
			padding-right: 30px;
		}

		&:last-child {
			width: 55%;
		}
	}

	&__field {
		position: relative;
		display: block;
		width: 100%;
		height: 45px;
		border: 1px solid #d9d9d9;
		background: #f7f7f7;
		margin-bottom: 32px;

		input, textarea {
			display: block;
			width: 100%;
			height: 100%;
			border: none;
			padding: 0 18px;
			background: transparent;
			color: black;

			&::placeholder {
				color: currentColor;
			}

			&:focus {
				outline: none;
			}
		}

		textarea {
			resize: none;
			padding: 12px 18px;
		}
	}

	&__field_txt {
		height: 198px;
	}

	&__field_error {
		&::before {
			position: absolute;
			left: -1px;
			right: -1px;
			top: 100%;
			height: 25px;
			content: 'Заполните это поле';
			background: @red;
			color: white;
			font-size: 13px;
			line-height: 25px;
			padding: 0 18px;
		}
	}

	&__agree {
		margin-bottom: 30px;
	}
}

.checkbox {
	cursor: pointer;

	input {
		display: none;
	}

	input:checked + .checkbox__custom::before {
		opacity: 1;	
	}

	&__custom {
		position: relative;
		top: 2px;
		display: inline-block;
		margin-right: 12px;
		width: 13px;
		height: 13px;
		border: 1px solid #cbcbcb;
		background: #f7f7f7;

		&::before {
			position: absolute;
			left: 3px;
			top: 1px;
			width: 3px;
			height: 6px;
			border-bottom: 2px solid @green;
			border-right: 2px solid @green;
			transform: rotate(35deg);
			content: '';
			opacity: 0;
			transition: all .1s;
		}
	}

	p {
		display: inline-block;
		margin: 0;
		font-size: 13px;
		font-weight: 300;
		line-height: 1;
	}
}

.breadcrumbs {
	padding: 26px 0 20px;
	background: url('../img/bg_3.png') center;

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		display: inline-block;
		vertical-align: top;

		&::after {
			position: relative;
			top: -3px;
			margin: 0 10px;
			width: 3px;
			height: 3px;
			content: '';
			border-top: 1px solid #9d9c9c;
			border-right: 1px solid #9d9c9c;
			display: inline-block;
			transform: rotate(45deg);
		}

		a {
			font-size: 13px;
			color: #9d9c9c;
			text-decoration: none;
			border-bottom: 1px dotted currentColor;
		}

		&:last-child {
			&::after {
				display: none;
			}

			a {
				pointer-events: none;
				border: none;	
			}
		}
	}
}

.map {
	position: relative;
}

#map {
	position: absolute;
	top: -50px;
	left: 0;
	width: 910px;
	height: 560px;
}

.menu {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 24px;
	height: 19px;
	border: none;
	border-top: 3px solid @green;
	border-bottom: 3px solid @green;
	background: none;
	display: none;

	&::before {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		content: '';
		width: 100%;
		height: 3px;
		background: @green;
	}

	&:focus {
		outline: none;
	}

	&.open {
		border: none;
		
		&::before,
		&::after {
			position: absolute;
			left: 50%;
			top: 50%;
			content: '';
			width: 100%;
			height: 3px;
			background: white;
			content: '';
		}

		&::before {
			transform: translate(-50%,-50%) rotate(45deg);
		}

		&::after {
			transform: translate(-50%,-50%) rotate(-45deg);
		}
	}
}

.modal {
	display: none;
	padding: 30px 150px 60px;
	width: 1030px;
	max-width: 100%;
}


/* * * * * * * * * * *
 * 					 *
 * Pages             *
 * Styles            *
 *                   *
 * * * * * * * * * * */



/* * * * * * * * * * *
* 					 *
* Block              *
* Styles             *
*                    *
* * * * * * * * * * */



/* * * * * * * * * * *
* 		   			 *
* Plugin             *
* Styles             *
*                    *
* * * * * * * * * * */

@import url('flickity.css');
@import url('jquery.fancybox.min.css');

/* * * * * * * * * * *
* 					 *
* Animation          *
* Keyframes          *
*                    *
* * * * * * * * * * */



/* * * * * * * * * * *
 * 					 *
 * Modals            *
 * Styles            *
 *                   *
 * * * * * * * * * * */



/* * * * * * * * * * *
* 					 *
* Media              *
* Queries            *
*                    *
* * * * * * * * * * */
@import url('1024.less');
@import url('600.less');