.search {
	margin: 30px 0 10px;
	padding: 36px 60px;
	background: url('../img/bg_3.png');

	&__form {
		display: table;
		width: 100%;
	}

	&__field {
		display: table-cell;
		vertical-align: middle;
		width: 90%;

		input {
			display: block;
			width: 92%;
			height: 43px;
			padding: 0;
			border: 1px solid #d9d9d9;
			background: #f7f7f7;
			padding: 0 17px;

			&:focus {
				outline: none;
			}
		}
	}

	&__btn {
		display: table-cell;
		vertical-align: middle;
		width: 10%;

		.btn {
			height: 45px;
		}
	}

	&__tip {
		margin: 10px 0;
		color: @green;
		font-size: 15px;
		font-style: italic;
		line-height: 1.4;

		strong {
			font-weight: normal;
			border-bottom: 1px dotted currentColor;
			cursor: pointer;

			&:hover {
				border: none
			}
		}
	}
}