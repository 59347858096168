.header {
	&__l-col {
		display: table-cell;
		vertical-align: middle;
		width: 34.15%;
	}

	&__r-col {
		display: table-cell;
		vertical-align: middle;
		width: 65.85%;
		padding: 0 30px 0 20px;
	}

	&__body {
		display: table;
		width: 100%;
		padding: 27px 0;
	}

	&__phone {
		font-size: 20px;
		color: @green;
		text-transform: uppercase;
		text-decoration: none;
		line-height: 0.9;
		margin-left: 12px;
		letter-spacing: .5px;
	}

	&__line {
		padding-left: 20px;
		margin-top: 29px;
	}

	&__social.social {
		float: right;
		vertical-align: top;
		margin-top: -12px;
	}

	&__logo.logo {
		padding: 0;
	}
}