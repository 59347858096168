.news-g {
	position: absolute;
	left: -190px;
	top: 0;
	width: 174px;

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__item {
		position: relative;
		z-index: 1;
		width: 80px;
		height: 80px;
		overflow: hidden;
		margin-bottom: 15px;
		cursor: pointer;

		&::before {
			position: absolute;
			z-index: 1;
			left: 0; top: 0; right: 0; bottom: 0;
			content: '';
			background: rgba(0,0,0,.5);
			opacity: 0;
			visibility: hidden;
			transition: all .3s;
		}

		&::after {
			position: absolute;
			z-index: 1;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			content: '';
			width: 40px;
			height: 40px;
			background: url('../img/zoom-in.svg') no-repeat center;
			background-size: contain;
			opacity: 0;
			visibility: hidden;
			transition: all .3s;
		}

		&:hover::before, &:hover::after {
			opacity: 1;
			visibility: visible;
		}

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			min-width: 100%;
			min-height: 100%;
		}
	}
}