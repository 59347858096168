.footer {
	&__body {
		padding: 35px 0;
		display: flex;
		justify-content: space-between;
	}

	&__top {
		background: url('../img/shtrih_bg.jpg') center;
	}

	&__contacts {
		margin-top: 30px;
		width: 250px;
		font-weight: 300;

		p {
			display: inline-block;
		}

		* {
			background: white;
			padding: 8px 10px;
		}
	}

	&__social.social {
		margin-top: 25px;
	}

	&__link {
		color: black;
		text-decoration: none;
	}

	&__bot {
		padding: 55px 0 75px;
		background: url('../img/bg_3.png') center;
	}
}

.copyright {
	position: relative;

	p {
		margin: 0;
		font-size: 15px;
		color: rgb(51, 51, 51);		
		line-height: 1.4;
	}

	&__letsrock {
		position: absolute;
		right: 0;
		bottom: 0;

		svg {
			width: 115px;
			height: 25px;
			fill: @green;
		}
	}
}

.tel {
	font-size: 24px;
	font-weight: 500;
	color: rgb(51, 51, 51);
	line-height: 0.875;
	text-decoration: none;
	background: white;
	padding: 0 4px;
}