.section {
	padding: 27px 0;
	// overflow-x: hidden;

	&__block {
		padding: 20px 0;

		&:nth-child(odd) {
			background: url('../img/shtrih_bg.jpg') center repeat;
		}

		&:last-child {
			padding-bottom: 0;
		}

		.container {
			display: table;
		}
	}

	&__block_empt {
		height: 120px;
		margin-bottom: -80px;
	}

	&__list {
		margin: 30px 0;
		padding: 0;
		list-style: none;
	}

	&__cell {
		display: table-cell;
		vertical-align: top;
		width: 50%;

		&:last-child {
			padding-left: 20px;
		}
	}

	&__inf {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;

		p {
			line-height: 2;
		}
	}

	&__top {
		position: relative;
		padding: 20px 0 0;

		&::before {
			position: absolute;
			z-index: -1;
			left: 50%; top: 0;
			transform: translateX(-50%);
			width: 100vw;
			height: 115%;
			content: '';
			background: url('../img/shtrih_bg.jpg') center repeat;
		}
	}
}

.section_bg {
	background: url('../img/shtrih_bg.jpg') center repeat;
}

.index .section_bg {
	background: none;
}

.section_act {
	padding: 100px 0;
	background: url('../img/map.png') no-repeat center;
}

.welc {
	&__ban {
		margin-bottom: 65px;
		position: relative;
		height: 380px;
		background: url('../img/welcban.jpg') no-repeat center;

		p {
			font-weight: 500;
			font-size: 24px;
			color: rgb(255, 255, 255);
			line-height: 1.25;
			margin: 0;
		}
	}

	&__ban-text {
		position: absolute;
		left: 70px;
		top: 50%;
		transform: translateY(-50%);
	}

	&__sup {
		position: relative;
		width: 100%;
		max-width: 1030px;
		margin: 0 auto 30px;
	}

	&__sup-logo {
		position: absolute;
		z-index: 3;
		left: 0;
		top: -30px;
		padding: 40px 50px 60px;
		width: 400px;
		text-align: center;
		background: url('../img/bg_3.png') center repeat;

		img {
			width: 295px;
		}

		p {
			margin: 0 0 30px;
			font-size: 15px;
			font-weight: 500;
			color: rgb(51, 51, 51);
			text-transform: uppercase;
			line-height: 1.333;
		}
	}

	&__sup-content {
		position: relative;
		height: 260px;
		max-width: 700px;
		width: 100%;
		margin: 0 0 0 auto;
		background: url('../img/welcsup.jpg') no-repeat center;

		p {
			position: absolute;
			left: 53%;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 500px;
			margin: 0;
			font-size: 15px;
			font-weight: 500;
			color: rgb(255, 255, 255);
			line-height: 1.4;
			text-align: left;
			text-indent: 36px;
		}
	}
}

.newslist {
	display: flex;
	flex-wrap: wrap;

	&__tabs {
		width: 100%;
		margin-bottom: 20px;
		text-align: right;

		.btn {
			margin-bottom: 5px;
		}
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		position: relative;
		width: 32%;
		height: 185px;
		padding: 30px;
		margin-bottom: 20px;
		background: white;
		border: 2px solid @green;
		transition: all .3s;
		color: black;
		font-weight: 500;
		margin-right: 20px;

		&:nth-child(3n) {
			margin-right: 0;
		}

		&:hover {
			background: @green;
			color: white;

			.newslist__date {
				color: white;
			}
		}

		a {
			position: absolute;
			left: 0; top: 0; right: 0; bottom: 0;
		}
	}

	&__item_more {
		display: none;
		
		&:hover {
			span {
				color: white;
			}

			svg {
				fill: white;
			}
		}
		span {
			display: inline-block;
			position: absolute;
			left: 55%;
			top: 50%;
			transform: translate(-50%,-50%);
			font-size: 14px;
			text-transform: uppercase;
			color: @green;
			border-bottom: 1px solid currentColor;

			svg {
				position: absolute;
				left: -50px;
				top: 50%;
				transform: translateY(-50%);
				width: 34px;
				height: 35px;
				fill: @green;
			}
		}
	}

	&__date {
		position: relative;
		color: @green;
		transition: all .3s;
		font-size: 14px;

		&::before {
			position: absolute;
			left: -15px;
			top: 5px;
			width: 6px;
			height: 6px;
			content: '';
			background: currentColor;
		}
	}

	&__title {
		font-size: 20px;
		max-height: 95px;
		overflow: hidden;
	}
}

.newslist_page {
	display: block;
}

.newslist_more {
	margin: 40px 0 0;

	.newslist__item {
		margin-bottom: 0;
	}
}

.activity {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	counter-reset: item;
	margin: 50px 0;

	&__item {
		position: relative;
		width: 33%;
		counter-increment: item;
		padding-left: 70px;
		padding-right: 10px;
		margin-bottom: 40px;

		&::before {
			position: absolute;
			left: 0;
			content: '0'counter(item);
			font-size: 44px;
			font-weight: 500;
			color: @green;
			line-height: .8;
		}

		p {
			margin: 0;
			font-size: 15px;
			font-weight: 300;
		}
	}
}

.banner {
	position: relative;
	z-index: 1;
	height: 380px;
	overflow: hidden;

	&::before {
		position: absolute;
		left: 0; top: 0; right: 0; bottom: 0;
		content: '';
		background: rgba(0,0,0,.1);
	}

	&__img {
		position: absolute;
		z-index: -1;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}

	&__content {
		position: absolute;
		left: 70px;
		top: 50%;
		transform: translateY(-50%);
		color: white;
		max-width: 600px;
	}

	&__caption {
		font-size: 16px;
		font-weight: 500;
		text-decoration: underline;
	}

	&__title {
		font-size: 24px;
		font-weight: 500;
	}
}

.consult {
	padding: 30px 150px;
	border: 1px solid #f1ece4;	
	background: white;
}

