.pagination {
	margin: 50px 0;

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		display: inline-block;
		vertical-align: top;
		margin: 0 5px;

		a {
			font-size: 15px;
			padding: 7px 12px;
			color: #828282;
			text-decoration: none;

			&:hover {
				color: @green;
			}
		}

		&.active {
			a {
				pointer-events: none;
				color: black;
				background: #f1ece4;
			}
		}
	}

	&__arrow {
		position: relative;
		padding: 0 1px;

		&::before {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			content: '';
			border: 8px solid transparent;
		}
	}

	&__arrow_prev {
		&::before {
			right: 0;
			border-right-color: @green;
		}
	}

	&__arrow_next {
		&::before {
			left: 0;
			border-left-color: @green;
		}
	}
}