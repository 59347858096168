.gallery {
	position: relative;
	padding: 30px 0 10px;
	margin-bottom: 30px;

	&::before {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		height: 100%;
		content: '';
		background: url('../img/shtrih_bg.jpg') center;
	}

	&__list {
		font-size: 0;
	}

	&__item {
		position: relative;
		display: inline-block;
		width: 230px;
		height: 150px;
		overflow: hidden;
		margin-right: 20px;
		margin-bottom: 20px;

		&:nth-child(3n) {
			margin-right: 0;
		}

		&::before {
			position: absolute;
			left: 0; top: 0; right: 0; bottom: 0;
			content: '';
			background: rgba(0,0,0,.5);
			opacity: 0;
			visibility: hidden;
			transition: all .3s;
		}

		&::after {
			position: absolute;
			z-index: 1;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			content: '';
			width: 40px;
			height: 40px;
			background: url('../img/zoom-in.svg') no-repeat center;
			background-size: contain;
			opacity: 0;
			visibility: hidden;
			transition: all .3s;
		}

		&:hover::before, &:hover::after {
			opacity: 1;
			visibility: visible;
		}
	}
}