.nav {
	display: flex;
	justify-content: space-between;
	padding-left: 10px;

	&.open {
		display: block;
	}

	&__item {
		position: relative;

		&:hover .nav__link {
			&::before,
			&::after {
				opacity: 1;
				visibility: visible;
			}

			&::before {
				left: -10px;
			}

			&::after {
				right: -10px;
			}
		}

		&:hover .nav__drop {
			opacity: 1;
			visibility: visible;
			margin-top: 16px;
		}
	}

	&__item_drop {
		&::before {
			display: none;
			position: absolute;
			right: 20px;
			top: 20px;
			transform: scaleY(1.3);
			content: '';
			border: 6px solid transparent;
			border-top-color: white;
		}

		&.open {
			&::before {
				top: 15px;
				border: 6px solid transparent;
				border-bottom-color: white;
			}

			.nav__drop {
				display: block;
				margin: 0;
			}
		}
	}

	&__link {
		position: relative;
		font-size: 15px;
		font-weight: 500;
		color: rgb(108, 108, 108);
		text-transform: uppercase;
		line-height: 1.2;
		text-align: right;
		text-decoration: none;

		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			opacity: 0;
			visibility: hidden;
			margin-top: -1px;
			transition: all .3s;
			pointer-events: none;
		}

		&::before {
			left: -15px;
			content: '[';
			color: @green;
			font-size: 20px;
		}

		&::after {
			right: -15px;
			content: ']';
			color: @green;
			font-size: 20px;
		}

		&.current {
			pointer-events: none;

			&::before,
			&::after {
				opacity: 1;
				color: currentColor;
				visibility: visible;
			}

			&::before {
				left: -10px;
			}

			&::after {
				right: -10px;
			}
		}
	}

	&__drop {
		position: absolute;
		z-index: 9;
		left: 50%;
		transform: translateX(-50%);
		top: 100%;
		background: @green;
		padding: 0 15px;
		margin: 0;
		list-style: none;
		width: 206px;
		text-align: center;
		opacity: 0;
		visibility: hidden;
		transition: all .25s;

		&::before {
			position: absolute;
			left: 0;
			top: -15px;
			height: 20px;
			width: 100%;
			content: '';
		}

		a {	
			display: block;
			padding: 15px 0;
			font-size: 13px;
			font-weight: 500;
			color: white;
			text-transform: uppercase;
			text-decoration: none;
			border-bottom: 1px solid #4c8f54;

			&:hover {
				font-weight: normal;
			}
		}

		li:last-child a {
			border: none;
		}
	}
}