.contacts {
	padding: 10px 0 0 40px;

	p {
		margin: 0 0 5px;
	}

	&__line {
		margin-bottom: 20px;
	}

	&__important {
		display: inline-block;
		font-size: 17px;
		background: white;
	}

	&__link {
		font-size: 17px;
		color: @green;
		line-height: 1.235;
		background: white;
	}

	&__social.social {
		margin: 20px 0;
	}
}

.contacts-personal {
	margin: 40px 0 0;
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding-left: 40px;
		font-size: 17px;
		margin-bottom: 30px;	

		&:last-child {
			margin-bottom: 0;
		}

		&::before {
			position: absolute;
			left: 0;
			top: 8px;
			width: 17px;
			height: 2px;
			content: '';
			background: @green;
		}

		p {
			margin: 0 0 5px;
		}

		a {
			color: @green;
		}

		span {
			display: inline-block;
			margin-right: 10px;
		}
	}
}