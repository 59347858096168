﻿/* This stylesheet generated by Transfonter (https://transfonter.org) on March 6, 2018 4:12 AM */

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Medium.eot');
    src: url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Medium.woff2') format('woff2'),
        url('../fonts/Rubik-Medium.woff') format('woff'),
        url('../fonts/Rubik-Medium.ttf') format('truetype'),
        url('../fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Regular.eot');
    src: url('../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Regular.woff2') format('woff2'),
        url('../fonts/Rubik-Regular.woff') format('woff'),
        url('../fonts/Rubik-Regular.ttf') format('truetype'),
        url('../fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Italic.eot');
    src: url('../fonts/Rubik-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Italic.woff2') format('woff2'),
        url('../fonts/Rubik-Italic.woff') format('woff'),
        url('../fonts/Rubik-Italic.ttf') format('truetype'),
        url('../fonts/Rubik-Italic.svg#Rubik-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Bold.eot');
    src: url('../fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Bold.woff2') format('woff2'),
        url('../fonts/Rubik-Bold.woff') format('woff'),
        url('../fonts/Rubik-Bold.ttf') format('truetype'),
        url('../fonts/Rubik-Bold.svg#Rubik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Black.eot');
    src: url('../fonts/Rubik-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Black.woff2') format('woff2'),
        url('../fonts/Rubik-Black.woff') format('woff'),
        url('../fonts/Rubik-Black.ttf') format('truetype'),
        url('../fonts/Rubik-Black.svg#Rubik-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Light.eot');
    src: url('../fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Light.woff2') format('woff2'),
        url('../fonts/Rubik-Light.woff') format('woff'),
        url('../fonts/Rubik-Light.ttf') format('truetype'),
        url('../fonts/Rubik-Light.svg#Rubik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-BoldItalic.eot');
    src: url('../fonts/Rubik-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-BoldItalic.woff2') format('woff2'),
        url('../fonts/Rubik-BoldItalic.woff') format('woff'),
        url('../fonts/Rubik-BoldItalic.ttf') format('truetype'),
        url('../fonts/Rubik-BoldItalic.svg#Rubik-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-LightItalic.eot');
    src: url('../fonts/Rubik-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-LightItalic.woff2') format('woff2'),
        url('../fonts/Rubik-LightItalic.woff') format('woff'),
        url('../fonts/Rubik-LightItalic.ttf') format('truetype'),
        url('../fonts/Rubik-LightItalic.svg#Rubik-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-BlackItalic.eot');
    src: url('../fonts/Rubik-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-BlackItalic.woff2') format('woff2'),
        url('../fonts/Rubik-BlackItalic.woff') format('woff'),
        url('../fonts/Rubik-BlackItalic.ttf') format('truetype'),
        url('../fonts/Rubik-BlackItalic.svg#Rubik-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-MediumItalic.eot');
    src: url('../fonts/Rubik-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-MediumItalic.woff2') format('woff2'),
        url('../fonts/Rubik-MediumItalic.woff') format('woff'),
        url('../fonts/Rubik-MediumItalic.ttf') format('truetype'),
        url('../fonts/Rubik-MediumItalic.svg#Rubik-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}
